import React, { useState, useEffect } from "react";

function App() {

  return (
    <div id="wrapper">
      <div id="banner">
        <div id="logo" />
      </div>
      <div id="aboutus">
        <div className="content">
          <h2><span>About Us</span><div></div></h2>
          <span className="description">
            Wij bieden een breed aanbod aan immobiliën in Antwerpen. Ook bieden wie ondersteuning bij residentieel en commercieel vastgoed, zowel nieuwbouw als opbrengsteigendommen en staan in voor de verhuring van honderden panden. Informeer nu voor meer info over het aankopen of verkopen van vastgoed.
          </span>
        </div>
      </div>
      <div id="impressions">
        <h2><span>Impressions</span><div></div></h2>
        <div className="images">
          <div id="impression-1"></div><div id="impression-2"></div>
          <div id="impression-3"></div><div id="impression-4"></div>
        </div>
      </div>
      <div id="contact">
        <div id="contact-wrapper">
          <div id="contact-image" />
          <div id="contact-details">
            <h2><span>Contact us</span><div></div></h2>
            <h3>Maxim Van Hoorebeeck</h3>
            <span className="description">
              <a href="mailto:maxim@48invest.be" target="_blank">maxim@48invest.be</a><br />
              <a href="tel:0032491377929" target="_blank">+32 491 377 929</a>
            </span>

            <h3>Glenn Van Roy</h3>
            <span className="description">
              <a href="mailto:glenn@48invest.be" target="_blank">glenn@48invest.be</a><br />
              <a href="tel:0032485923080" target="_blank">+32 485 923 080</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
